<template>
  <router-view
    :class="{
      'modal-open': modalOpen
    }"
  />
  <div
    v-if="modalOpen"
    class="modal-backdrop show"
  />
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  head: {
    title: 'xFlo'
  },
  computed: {
    ...mapGetters({
      modalOpen: 'getModalOpen'
    })
  }
}
</script>

<style>
.modal-open {
  overflow: hidden;
  padding-right: 0;
}
</style>
