<template>
  <div>
    <label
      v-if="label"
      :for="id"
      class="form-label"
    >
      {{ label }}
    </label>
    <input
      :id="id"
      :value="modelValue"
      :type="type"
      :required="required"
      :disabled="disabled"
      :placeholder="placeholder"
      class="form-control"
      @input="$emit('update:modelValue', $event.target.value)"
    >
    <div
      v-if="helpText"
      class="form-text"
    >
      {{ helpText }}
    </div>
  </div>
</template>

<script>

export default {
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    helpText: {
      type: String,
      default: null
    }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      id: null
    }
  },
  mounted () {
    this.id = this._uid
  }
}
</script>
