<template>
  <div
    class="attachment alert mb-2"
    :class="{
      'border bg-light': !file.error && !file.url,
      'alert-danger': file.error,
      'alert-success': file.url
    }"
  >
    <div class="d-flex">
      <svg
        class="flex-shrink-0"
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
      >
        <path
          v-if="file.error"
          fill="#842029"
          d="M11 15h2v2h-2v-2zm0-8h2v6h-2V7zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
        />
        <path
          v-else-if="file.url"
          fill="#0f5132"
          d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
        />
        <path
          v-else
          class="pulse"
          fill="#6c757d"
          d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M7,9l1.41,1.41L11,7.83V16h2V7.83l2.59,2.58L17,9l-5-5L7,9z"
        />
      </svg>
      <div class="flex-grow-1 ps-2 attachment__file-name">
        <span v-if="file.error">
          {{ file.originalName }} is too large.
        </span>
        <a
          v-else-if="file.url"
          target="_blank"
          :href="file.url"
        >{{ file.originalName }}</a>
        <span v-else>
          Uploading {{ file.originalName }}&hellip;
        </span>
      </div>
      <svg
        v-if="file.url || file.error"
        height="24px"
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        @click="$emit('delete')"
      >
        <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Attachment',
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  emits: ['delete']
}
</script>

<style lang="scss" scoped>
.attachment {
  overflow: hidden;

  &__file-name {

    a, span {
      white-space: nowrap;
    }

    svg {
      width: 24px;
    }
  }
}
.pulse {
  animation: pulse 1000ms linear infinite;
}

@keyframes pulse {
  0% { opacity: 1 }
  50% { opacity: 0 }
  100% { opacity: 1 }
}
</style>
