import { createStore } from 'vuex'

export default createStore({
  state: {
    files: [],
    modalOpen: false
  },
  getters: {
    getModalOpen: state => {
      return state.modalOpen
    },
    getFiles: state => {
      return state.files
    }
  },
  mutations: {
    setModalOpen: (state, payload) => {
      state.modalOpen = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
