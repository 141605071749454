<template>
  <div class="home">
    <NewTicket
      v-if="type && id"
      :type="type"
      :id="id"
    />
    <p
      v-else
      class="text-red-500"
    >
      No client specified.
    </p>
  </div>
</template>

<script>
import NewTicket from '@/components/NewTicket.vue'

export default {
  name: 'Home',
  components: {
    NewTicket
  },
  props: {
    type: {
      type: String,
      required: false,
      default: null
    },
    id: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>

<style lang="scss">
.home {
  margin: 0 auto;
  padding: 0 .5rem;
  max-width: 30rem;
}
</style>
