<template>
  <div
    class="alert alert-success"
    :class="{
      'alert-success': type === 'success',
      'alert-warning': type === 'warning',
      'alert-danger': type === 'danger'
    }"
    role="alert"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>
