import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/new/:type/:id',
    component: Home,
    props: true
  },
  {
    path: '/:type/:query',
    component: function () {
      return import(/* webpackChunkName: "kanban" */ '../views/Kanban.vue')
    },
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
