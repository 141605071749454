<template>
  <div>
    <form
      v-if="!success"
      @submit.prevent="submit"
    >
      <InputGroup
        v-model="form.fullName"
        :required="true"
        label="Full Name"
        class="mb-4"
      />

      <InputGroup
        v-model="form.requestTitle"
        :required="true"
        label="Request Title"
        placeholder="i.e. Changes to page layout"
        class="mb-4"
      />

      <div class="mb-4">
        <label
          for="requestType"
          class="form-label"
        >Request Type</label>
        <select
          id="requestType"
          v-model="form.requestType"
          class="form-select"
        >
          <option disabled>
            Please select&hellip;
          </option>
          <option>
            Web &mdash; Content Updates
          </option>
          <option>
            Web &mdash; Defect/Bug Report
          </option>
          <option>
            Web &mdash; Site Down
          </option>
          <option>
            Media &mdash; Analytics
          </option>
          <option>
            Media &mdash; SEO
          </option>
          <option>
            Media &mdash; Social
          </option>
        </select>

        <div
          v-if="form.requestType"
          class="mt-2 alert border bg-light"
        >
          <div class="form-check align-middle">
            <input
              id="priority"
              v-model="form.priority"
              type="checkbox"
              class="form-check-input"
            >
            <label
              class="form-check-label"
              for="priority"
            >
              <strong class="text-danger">
                This request is business critical.
              </strong>
              <br>
              <small class="text-muted">
                Please note, this request will be billed under SLA-1.
              </small>
            </label>
          </div>
        </div>
      </div>

      <InputGroup
        v-model="form.requestUrl"
        label="Request URL"
        placeholder="i.e. https://www.cube3.io"
        type="url"
        :required="form.requestType === 'WebOps'"
        class="mb-4"
      />

      <div class="mb-4">
        <label
          for="description"
          class="form-label"
        >Description</label>
        <textarea
          id="description"
          v-model="form.description"
          class="form-control"
          rows="3"
          minlength="100"
          maxlength="2000"
          required
        />
        <div class="form-text">
          Please describe your new requirement in as much detail as possible. You must include the details of the request in this field.
        </div>
      </div>

      <div class="mb-4">
        <label
          for="attachments"
          class="form-label"
        >Attachments</label>
        <input
          id="attachments"
          class="form-control mb-2"
          type="file"
          multiple
          @change="addAttachment"
        >
        <Attachment
          v-for="file in form.files"
          :key="file.name"
          :file="file"
          @delete="deleteAttachment(file)"
        />
        <div class="form-text">
          Where applicable or relevant, please provide attachments to assist us in understanding your requirement. Note the file size limit is 8 MB per file.
        </div>
      </div>

      <div class="mb-4 alert border bg-light">
        <div class="form-check">
          <input
            id="tos"
            type="checkbox"
            class="form-check-input"
            required
          >
          <label
            class="form-check-label"
            for="tos"
          >I accept the <a
            target="_blank"
            href="https://cube3.notion.site/SLA-Service-Level-Agreement-9f639f7c069349239c51b4c993e45d78"
          >Cube3 - SLA (Service Level Agreement)</a>.</label>
        </div>
      </div>

      <button
        v-if="!form.priority"
        type="submit"
        class="btn btn-lg btn-primary"
        :disabled="loading"
      >
        Submit Request
      </button>
      <button
        v-else
        type="submit"
        class="btn btn-lg btn-danger"
        :disabled="loading"
      >
        Submit Business Critical Request
      </button>
    </form>
    <Alert
      v-else
      type="success"
    >
      Success! <a
        href="#"
        class="alert-link"
        @click.stop="resetForm()"
      >Create another ticket &rarr;</a>
    </Alert>
  </div>
  <div class="mt-4">
    <small
      class="text-muted"
      @click="debug = !debug"
    >Debug</small>
    <pre v-if="debug">{{ form }}</pre>
  </div>
</template>

<script>
import InputGroup from '@/components/molecules/InputGroup'
import Attachment from '@/components/atoms/Attachment'
import Alert from '@/components/Alert'

export default {
  name: 'NewTicket',
  components: {
    InputGroup,
    Attachment,
    Alert
  },
  props: {
    type: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      apiBaseURL: '/.netlify/functions',
      s3BaseURL: 'https://cube3-client-file-uploads.s3.eu-west-2.amazonaws.com',
      form: {
        type: this.type,
        id: this.id,
        fullName: '',
        requestTitle: '',
        requestType: '',
        priority: false,
        requestUrl: '',
        files: []
      },
      loading: false,
      success: false,
      debug: false,
      maxFileSizeMB: 8
    }
  },
  computed: {
    maxFileSize () {
      return this.maxFileSizeMB * 1024 * 1024
    }
  },
  methods: {
    async submit () {
      this.loading = true
      const response = await fetch(`${this.apiBaseURL}/test`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.form)
      })
      if (response.ok) {
        this.loading = false
        this.success = true
      }
    },
    resetForm () {
      this.success = false
      this.form = {
        company: this.client,
        fullName: '',
        requestTitle: '',
        requestType: '',
        priority: false,
        requestUrl: '',
        files: []
      }
    },
    addAttachment (e) {
      const dir = this.form.company
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^a-zA-Z0-9-]/g, '')
      console.log(dir)
      this.loading = true
      const files = e.target.files
      const timestamp = Date.now()
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const fileName = `${dir}/${timestamp}-${file.name}`
        const reader = new FileReader()
        reader.addEventListener('loadend', async () => {
          this.form.files.push({
            originalName: file.name,
            name: fileName,
            type: file.type
          })

          // File too large
          if (reader.result.byteLength > this.maxFileSize) {
            this.form.files.find(o => o.name === fileName).error = 'File too big.'
            this.loading = false
            return false
          }

          const response = await fetch(this.apiBaseURL + '/upload-file', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              fileName: fileName,
              fileType: file.type
            })
          })
          const json = await response.json()
          await fetch(json.uploadURL, {
            method: 'PUT',
            body: new Blob([reader.result], { type: file.type })
          })

          this.form.files.find(o => o.name === fileName).url = encodeURI(`${this.s3BaseURL}/${fileName}`)
          this.loading = false
        })
        reader.readAsArrayBuffer(file)
      }
    },
    async deleteAttachment (file) {
      if (file.url) {
        await fetch(this.apiBaseURL + '/delete-attachment', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            key: file.name
          })
        })
      }
      this.form.files = this.form.files.filter(o => o.name !== file.name)
    },
    handleDrop (e) {
      const files = e.dataTransfer.files
      for (let i = 0; i < files.length; i++) {
        this.loading = true
        const file = files[i]
        const reader = new FileReader()
        reader.addEventListener('loadend', async () => {
          const response = await fetch(this.apiBaseURL + '/upload-file', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              fileName: file.name,
              fileType: file.type
            })
          })
          const json = await response.json()
          await fetch(json.uploadURL, {
            method: 'PUT',
            body: new Blob([reader.result], { type: file.type })
          })
          this.form.files.push({
            name: file.name,
            type: file.type,
            url: encodeURI(`${this.s3BaseURL}/${file.name}`)
          })
        })
        reader.readAsArrayBuffer(file)
        this.loading = false
      }
      return false
    }
  }
}
</script>

<style scoped lang="scss">
#drop {
  height: 4rem;
  border: 2px dashed #ced4da;
  border-radius: .25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

form > * {
  display: block;
}

button:disabled {
  opacity: .5;
}
</style>
